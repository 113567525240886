import React from 'react';
import logo from '../../ubeube.svg';

import classes from './StartPageContent.module.css';

const StartPageContent = () => {
  return (
    <main className={classes.starting}>
      <img src={logo} className={classes.logo} alt="logo" />
    </main>
  );
};

export default StartPageContent;
